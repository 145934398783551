
import { ref, reactive, onMounted } from "vue"
import CVInputRuleValue from "@/components/settings/render-instructions/cvinput/CVInputRuleValue.vue"
import RenderInstructionService from "@/services/RenderInstructionService"
import { useRoute } from "vue-router"
import SecondaryButton from "@/components/buttons/SecondaryButton.vue"
import RenderInstructionImport from "@/components/settings/render-instructions/RenderInstructionImport.vue"

export default {
  components: { CVInputRuleValue, SecondaryButton, RenderInstructionImport },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    openImport: {
      type: Boolean,
      default: false,
    },
    orgIdToSelect: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  setup(props: any) {
    const service = new RenderInstructionService("ricvinput")
    const route = useRoute()
    const organizationId = ref(route.params.organization_id ? route.params.organization_id : null)
    const additionalFields = reactive({
      alias: "",
      parameterFile: "",
      setting: 0,
      brightness: 0.0,
      contrast: 0.0,
      latitude: 0.0,
      edge: 0.0,
      noise: 0.0,
    })
    const columns = reactive([
      { field: "parameterFile", header: "Parameter File", width: "20rem" },
      { field: "setting", header: "Index", width: "4rem" },
      { field: "brightness", header: "Brightness", width: "6rem", icon: "Brightness6" },
      { field: "contrast", header: "Contrast", width: "5rem", icon: "ContrastCircle" },
      { field: "latitude", header: "Latitude", width: "5rem", icon: "ArrowExpandHorizontal" },
      { field: "noise", header: "Noise", width: "5rem", icon: "blur" },
      { field: "edge", header: "Edge", width: "5rem", icon: "BorderLeftVariant" },
    ])

    const collection = ref()

    onMounted(() => {
      collection.value.riImportDialog = props.openImport
    })

    function closeDialogs() {
      collection.value.addDialog = false
      collection.value.editDialog = false
      collection.value.duplicateDialog = false
      collection.value.riImportDialog = false
      collection.value.reload()
    }

    function showRiImportDialog() {
      collection.value.riImportDialog = true
    }

    return {
      service,
      additionalFields,
      organizationId,
      columns,
      collection,
      closeDialogs,
      showRiImportDialog,
    }
  },
}
