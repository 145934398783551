import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SecondaryButton = _resolveComponent("SecondaryButton")!
  const _component_CVInputRuleForm = _resolveComponent("CVInputRuleForm")!
  const _component_RenderInstructionImport = _resolveComponent("RenderInstructionImport")!
  const _component_RenderInstructionRuleCollection = _resolveComponent("RenderInstructionRuleCollection")!

  return (_openBlock(), _createBlock(_component_RenderInstructionRuleCollection, {
    ref: "collection",
    service: $setup.service,
    editFields: $setup.additionalFields,
    columns: $setup.columns
  }, {
    importButtonSlot: _withCtx(() => [
      _createVNode(_component_SecondaryButton, {
        label: "Import",
        onClick: $setup.showRiImportDialog
      }, null, 8, ["onClick"])
    ]),
    addSlot: _withCtx(() => [
      _createVNode(_component_CVInputRuleForm, {
        add: true,
        edit: false,
        duplicate: false,
        organization: $setup.collection.selectedOrganization,
        onClosedialogs: $setup.closeDialogs
      }, null, 8, ["organization", "onClosedialogs"])
    ]),
    editSlot: _withCtx(() => [
      _createVNode(_component_CVInputRuleForm, {
        add: false,
        edit: true,
        duplicate: false,
        onClosedialogs: $setup.closeDialogs,
        selectedRule: $setup.collection.selectedItems[0].data
      }, null, 8, ["onClosedialogs", "selectedRule"])
    ]),
    duplicateSlot: _withCtx(() => [
      _createVNode(_component_CVInputRuleForm, {
        add: false,
        edit: false,
        duplicate: true,
        onClosedialogs: $setup.closeDialogs,
        selectedRule: $setup.collection.selectedItems[0].data
      }, null, 8, ["onClosedialogs", "selectedRule"])
    ]),
    importRiSlot: _withCtx(() => [
      _createVNode(_component_RenderInstructionImport, {
        renderInstructionType: "ricvinput",
        columns: $setup.columns,
        organization: $props.organization,
        orgIdToSelect: $props.orgIdToSelect,
        title: $props.title ?? 'Context Vision',
        onReload: _cache[0] || (_cache[0] = ($event: any) => ($setup.collection.reload()))
      }, null, 8, ["columns", "organization", "orgIdToSelect", "title"])
    ]),
    _: 1
  }, 8, ["service", "editFields", "columns"]))
}